<template>
  <v-card
    class="elevation-0"
    outlined
  >
    <HeaderCard>
      <b>{{ 'title.invite'.translate() }}</b>
    </HeaderCard>

    <v-card-text style="padding-top: 0; padding-bottom: 0">
      <v-form
        ref="form"
        v-model="valid"
        autocomplete="off"
      >
        <IgnoreAutoFill />

        <v-layout
          wrap
          style="padding: 12px 0"
        >
          <v-flex xs12>
            <FieldReadOnly
              icon="mdi-shield-account"
              label="field.username"
              v-model="username"
              @on-enter="loginUser"
            />
          </v-flex>

          <v-flex xs12>
            <FieldPassword
              label="field.temporaryPassword"
              v-model="password"
              autofocus
              @on-enter="loginUser"
            />
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>

    <Message
      type="error"
      icon="mdi-cloud-alert"
      :show="showError"
      :message="auth.error"
    />
    <Message
      type="info"
      :dismissible="false"
      icon="mdi-cloud-alert"
      :show="infoMSG"
      :message="infoMSG"
      :extra="username"
    />

    <v-divider />

    <v-card-actions style="padding-top: 0.7em; padding-bottom: 0.7em;">
      <v-layout
        wrap
        justify-space-around
      >
        <v-btn
          text
          small
          color="primary"
          :to="linkLogin"
        >
          {{ 'btn.back'.translate() }}
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          :disabled="!valid"
          @click="loginUser"
          :loading="loading"
        >
          {{ 'btn.access'.translate() }}
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Message from '@/components/Message'
import HeaderCard from '@/components/HeaderCard'
import FieldReadOnly from '@/components/fields/FieldReadOnly'
import FieldPassword from '@/components/fields/FieldPassword'
import { ROUTE_AUTH_LOGIN, ACTION_USER_LOGIN } from '@/constants'
import IgnoreAutoFill from './IgnoreAutoFill'

export default {
  components: {
    Message,
    HeaderCard,

    FieldReadOnly,
    FieldPassword,
    IgnoreAutoFill
  },

  data () {
    return {
      valid: true,
      loading: false,
      password: null,
      showError: false
    }
  },

  methods: {
    loginUser () {
      if (!this.valid) return false

      this.loading = true
      const { username, password } = this
      this.$store.dispatch(ACTION_USER_LOGIN, { username, password })
    }
  },

  computed: {
    linkLogin: () => ({ name: ROUTE_AUTH_LOGIN }),

    ...mapState({
      auth: ({ auth }) => ({ error: auth.error, loading: auth.loading }),
      infoMSG: ({ auth }) => auth.info ? auth.info : null,
      username ({ auth }) {
        return this.$route.query.username || (auth.userCognito ? auth.userCognito.idToken.payload.email : null)
      }
    })
  },

  watch: {
    password () {
      this.showError = false
    },

    'auth.loading': function (loading) {
      if (this.loading !== loading) this.loading = loading
    },

    'auth.error': function (error) {
      this.showError = Boolean(error)
    }
  }
}
</script>
